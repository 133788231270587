import { useEffect, useRef, useState } from "react"
import placeholder from "../img/loadImagePlaceholder.png"
import "./MyNfts.css"
import eventLogo1 from "../img/logo_examples/eventlogo1.png"
import eventLogo2 from "../img/logo_examples/eventlogo2.png"
import eventLogoCat from "../img/logo_examples/cat.jpg"
import eventLogo3 from "../img/logo_examples/eventlogo3.png"
import logo1 from "../img/logo_examples/horikita.jpeg"
import collectionlogo from "../img/logo_examples/collectionlogo.png"
import eventLogo4 from "../img/newDarkGradient.png"
import { useNavigate } from "react-router-dom"
import rubyLogo from "../img/logo_examples/ruby.jpeg"
import { ModalEventElement } from "../components/MyNfts/ModalEventElement"
import { getMyNftsData } from "../utils/mynftsConnections"


export const MyNfts = ({username, setIsBackButtonShow, setIfNotSavingData, firstName, telegramId}) => {
    const PORT = process.env.REACT_APP_API_URL.split(":")[2] ? `:${process.env.REACT_APP_API_URL.split(":")[2]}` : ""
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false)

    const [event, setEvent] = useState(null)

    const imageRef = useRef(null)
    const imageContainerRef = useRef(null)

    const onBackButtonClick = () => {
        navigate(-1)
      }
    
    const [eventsInfo, setEventsInfo] = useState([])
    const [eventElementWidth, setEventElementWidth] = useState(null)

    useEffect(() => {


        setIfNotSavingData(false)
        setIsBackButtonShow(true)
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.onEvent('backButtonClicked', onBackButtonClick);
      }
      return () => {
          if (window.Telegram && window.Telegram.WebApp) {
              window.Telegram.WebApp.offEvent('backButtonClicked', onBackButtonClick);
          }
         
      
      }
   
    }, [])

   
    useEffect( () => {
        const getMyNftsDataHandle = async (telegramId) => {
            const nfts_data = await getMyNftsData(telegramId) 
            if (nfts_data === null) {
                if (PORT === ":8080") {
                setEventsInfo([{
                    collection_name: "Collection1 Name",
                    event_name: "Event1 Name",
                    description: "Description 1",
                    collection_logo_url: collectionlogo,
                    event_logo_url: eventLogo4
                },
                {
                    collection_name: "Collection2 Name",
                    event_name: "Event2 Name",
                    description: "Description\nDescription\nDescription\n\nDescription\nDescription\nHello World",
                    collection_logo_url: collectionlogo,
                    event_logo_url: eventLogo2
                },
                {
                    collection_name: "Collection3 Name",
                    event_name: "Event3 Name",
                    description: "Hello World\nHello World\n\nHello World",
                    collection_logo_url: collectionlogo,
                    event_logo_url: eventLogo3
                },
                {
                    collection_name: "Collection4 Name",
                    event_name: "Event4 Name",
                    description: "Hello logo1 logo1 logo1",
                    collection_logo_url: logo1,
                    event_logo_url: rubyLogo
                },
                {
                    collection_name: "Collection5 Name",
                    event_name: "Event5 Name",
                    description: "Ura ura\nurarararara nya",
                    collection_logo_url: logo1,
                    event_logo_url: eventLogoCat
                },
                {
                    collection_name: "Collection6 Name",
                    event_name: "Event6 Name",
                    description: "Puk puk puk sheptun cucumber",
                    collection_logo_url: rubyLogo,
                    event_logo_url: logo1
                },
                {
                    collection_name: "Collection7 Name",
                    event_name: "Event7 Name",
                    description: "I like to eat cucumber",
                    collection_logo_url: eventLogo2,
                    event_logo_url: eventLogo1
                }
            
            ])
                
            }

            return 
        }
                 // "nft_name":
        //     "nft_description":
        //     "nft_image_url":
        //     "collection_name":
        //     "collection_description": 
        //     "collection_image_url":
        //     "event_name":
            const newEventsList = []
            for (const event of nfts_data) {
                newEventsList.push({
                    collection_name: event.collection_name,
                    event_name: event.event_name,
                    description: event.nft_description,
                    collection_logo_url: event.collection_image_url,
                    event_logo_url: event.nft_image_url,

                });
            }
            setEventsInfo(newEventsList)
        }
        getMyNftsDataHandle(telegramId)
    }, [telegramId])



    useEffect(() => {
        if (eventsInfo.length < 1) {
            return
        }
        const eventItem = document.querySelector('.event-item');
        if (eventItem) {
            const rect = eventItem.getBoundingClientRect();
            const width = rect.width;
            console.log('Ширина контейнера events-box:', width);
            setEventElementWidth(width)
        }
        
    }, [eventsInfo]);

    const viewCard = (event) => {        
        setEvent(event)
        setIsModalOpen(true)
    }

    const onViewCardClose = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (isModalOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }
        
        // Очистка при размонтировании компонента
        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [isModalOpen]);

    return (
        <>
            <div className="my-nfts-container" style={{marginBottom: "25px"}}>
                <p className="my-nfts-title">Collectibles</p>
                <div className="events-box">
                {eventsInfo.map((event, index) => (
                <>
                    <div className="event-item" onClick={() => viewCard(event)}>
                        <div className="event-logo-box" ref={imageContainerRef} style={{height: `${eventElementWidth}px`}}>
                            <img ref={imageRef} src={event.event_logo_url} alt={`${event.event_name} logo`}  className="img"/>
                        </div>
                        <div className="event-info">
                            <p className="event-name">{event.event_name}</p>
                            <p className="event-collection">{event.collection_name}</p>
                        </div>
                        
                    </div>
                    
                </>
                
            ))}
                </div>
            </div>
            {event && (
                 <ModalEventElement 
                    key={`modal-${event.event_name}-${event.collection_name}`}
                    isOpen={isModalOpen} 
                    onClose={onViewCardClose} 
                    eventName={event.event_name} 
                    collectionName={event.collection_name} 
                    description={event.description} 
                    collectionLogoUrl={event.collection_logo_url} 
                    firstName={firstName} 
                    username={username} 
                    eventLogoUrl={event.event_logo_url}
                />
            )}
        </>
    )
}