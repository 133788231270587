import "./Onboarding.css"

import whiteArrowBack from "../../img/whiteArrowBack.svg"
import whiteArrowNext from "../../img/whiteArrowNext.svg"
import React, { useEffect, useRef, useState } from "react"

import firstVideo1 from "../../videos/onboarding/1/1.mp4"
import secondVideo1 from "../../videos/onboarding/1/2.mp4"
import thirdVideo1 from "../../videos/onboarding/1/3.mp4"
import fourthVideo1 from "../../videos/onboarding/1/4.mp4"
import fifthVideo1 from "../../videos/onboarding/1/5.mp4"

import firstVideo2 from "../../videos/onboarding/2/1.mp4"
import secondVideo2 from "../../videos/onboarding/2/2.mp4"
import thirdVideo2 from "../../videos/onboarding/2/3.mp4"
import fourthVideo2 from "../../videos/onboarding/2/4.mp4"
import fifthVideo2 from "../../videos/onboarding/2/5.mp4"
import sixthVideo2 from "../../videos/onboarding/2/6.mp4"
import seventhVideo2 from "../../videos/onboarding/2/7.mp4"
import eighthVideo2 from "../../videos/onboarding/2/8.mp4"
import ninthVideo2 from "../../videos/onboarding/2/9.mp4"

import firstVideo3 from "../../videos/onboarding/3/1.mp4"
import secondVideo3 from "../../videos/onboarding/3/2.mp4"
import thirdVideo3 from "../../videos/onboarding/3/3.mp4"


const VideoComponent = React.memo(({ src, isPlaying, onLoadedMetadata, onTimeUpdate, onEnded }) => {
    const videoRef = useRef(null);
    const containerRef = useRef(null);
   
    
    // Добавляем обработчики для паузы/воспроизведения
    const handlePointerDown = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    const handlePointerUp = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    useEffect(() => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    }, [isPlaying, src]);

    return (
        <div 
            className="video-container" 
            ref={containerRef}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerUp}
        >
            <video 
                ref={videoRef} 
                loop 
                muted 
                playsInline 
                webkit-playsinline="true"
                x5-playsinline="true"
                className="onboarding-image" 
                onTimeUpdate={onTimeUpdate} 
                onEnded={onEnded} 
                onLoadedMetadata={(e) => {
                    onLoadedMetadata(e.target);
                    // if (containerRef.current) {
                    //     containerRef.current.style.width = `${containerRef.current.offsetHeight / videoRatio}px`;
                    // }
                }}
            >
                <source src={src} type="video/mp4" />
                Ваш браузер не поддерживает видео.
            </video>
        </div>
    );
});


export const Onboarding = ({isOpen, onClose, videoLabels, videosArr}) => {


    const [isPlaying, setIsPlaying] = useState(true)
    // const gifArr = [firstSlide, secondSlide, thirdSlide, fourthSlide, fifthSlide]
  
    const [gifNumber, setGifNumber] = useState(0)
    const [videoDuration, setVideoDuration] = useState(0);


    
    
    const [progress, setProgress] = useState(Array(videosArr.length).fill(0));
   
   

    const backClick = () => {
        if (gifNumber > 0) {
            setGifNumber(gifNumber-1)
        }
        
    }
    const nextClick = () => {
        if (gifNumber < videosArr.length -1) {
            setGifNumber(gifNumber+1)
        }
        else {
            setGifNumber(0)
            onClose()
        }
        
    }

    const handleLoadedMetadata = (video) => {
        setVideoDuration(video.duration);
    };

    const handleTimeUpdate = (event) => {
        const video = event.target;
        const currentTime = video.currentTime;
        if (videoDuration > 0) {
            requestAnimationFrame(() => {
                const newProgress = progress.map((_, index) => {
                    if (index < gifNumber) return 100;
                    if (index === gifNumber) return (currentTime / videoDuration) * 100;
                    return 0;
                });
                setProgress(newProgress);
            });
        }
    };

    const handleVideoEnded = () => {
        // Заполняем все индикаторы по завершению видео
        setProgress(Array(videosArr.length).fill(100));
    };

    useEffect(() => {
        setProgress(Array(videosArr.length).fill(0));
    }, [gifNumber]);

    // useEffect(() => {
    //     setVideoSrc(videosArr[gifNumber]);
    //     if (gifNumber < 0 || gifNumber >= timeArr.length) return;

    //     const duration = timeArr[gifNumber] * 1000; // переводим в миллисекунды
    //     setProgress([0,0,0,0,0]);
    //     setIsAnimating(true);

    //     const interval = setInterval(() => {
    //         setProgress(prev => {
    //             const newProgress = [...prev];
    //             const increment = (100 / (duration / 100)); // сколько процентов добавлять
                
    //             if (newProgress[gifNumber] >= 100) {
                     
    //                 setIsAnimating(false); // Останавливаем анимацию
    //                 return [0,0,0,0,0]; // Возвращаем старое состояние
    //             }

    //             newProgress[gifNumber] = Math.min(newProgress[gifNumber] + increment, 100);
    //             return newProgress; // обновляем только текущую полоску
    //         });
    //     }, 100);

    //     return () => clearInterval(interval); // очищаем интервал при размонтировании
    // }, [gifNumber]);


    if (!isOpen) {
        return null
    }
    return (
        <div className="onboarding-container">
            <div className="onboarding-content">
            <div className="gif-indicator">
            {Array.from({ length: videosArr.length }, (_, index) => (
                    <React.Fragment key={`${videoLabels[index]}-${index}`}>
                        {index !== gifNumber ? (
                            <div className={`gif-indicator-item ${gifNumber > index ? "preview" : ""}`} style={{width: `${100/videosArr.length}%`}}></div>
                        ) : (
                            <div className={`gif-indicator-item`} style={{ position: "relative", width: `${100/videosArr.length}%` }}>
                                <span className="onboarding-animated-indicator" style={{
                                    width: `${progress[index]}%`,
                                    transition: 'width 0.3s ease-out'
                                }}></span>
                            </div>
                        )}
                    </React.Fragment>
                ))}
        </div>
            <div className="gif-content">
                <p className="gif-label">{videoLabels[gifNumber]}</p>

                <VideoComponent onTimeUpdate={handleTimeUpdate} onEnded={handleVideoEnded} key={gifNumber} src={videosArr[gifNumber]} isPlaying={isPlaying} onLoadedMetadata={handleLoadedMetadata} />

                
            </div>
            </div>

            <div className="btn onboarding-skip-btn" onClick={() => {
                onClose()
                if (gifNumber === videosArr.length - 1) {
                    setGifNumber(0)
                }
            }}>{gifNumber === videosArr.length - 1 ? "Close" : "Skip"}</div>

            <div className="onboarding-arrows-box">
                {gifNumber > 0 ? (
                    <img src={whiteArrowBack} alt="Previous slide" onClick={backClick} className="onboarding-arrow-back no-select"/>
                )
                : (
                    <div>
                    </div>
                )

                }

                {gifNumber < videosArr.length ? (
                    <img src={whiteArrowNext} alt="Next slide" onClick={nextClick} className="onboarding-arrow-next no-select" />
                )
                : (
                    <div>
                    </div>
                )

                }
                
                
            </div>
        </div>
    )

}

export const FirstOnboarding = ({isOpen, onClose}) => {
    const videoLabels = ["Let's connect your wallet", "How to make a wallet", "Link your email", "Claim NFT", "Your NFT in TON Space"]
    const videosArr = [firstVideo1, secondVideo1, thirdVideo1, fourthVideo1, fifthVideo1]
    return (
        <>
        <Onboarding isOpen={isOpen} onClose={onClose} videoLabels={videoLabels} videosArr={videosArr}/>
        </>
    )
}

export const SecondOnboarding = ({isOpen, onClose}) => {
    const videoLabels = ["How to create event", "Upload or generate event logoand collection logo", "Come up with a collection name, event name, description and count of NFTs", "Set the start date for the event", "Set the end date for the event", "Select time zone", "Create a password", "Mark the channels you need to subscribe to according to the terms of the giveaway", "Make a post"]
    const videosArr = [firstVideo2, secondVideo2, thirdVideo2, fourthVideo2, fifthVideo2, sixthVideo2, seventhVideo2, eighthVideo2, ninthVideo2]
    return (
        <>
        <Onboarding isOpen={isOpen} onClose={onClose} videoLabels={videoLabels} videosArr={videosArr}/>
        </>
    )
}

export const ThirdOnboarding = ({isOpen, onClose}) => {
    const videoLabels = ["You can change collection name in your profile", "Click to icon to open editor", "Edit logo and name. You can save changes for a fee"]
    const videosArr = [firstVideo3, secondVideo3, thirdVideo3]
    return (
        <>
        <Onboarding isOpen={isOpen} onClose={onClose} videoLabels={videoLabels} videosArr={videosArr}/>
        </>
    )
}