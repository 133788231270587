import './App.css';
import React, { useState, useEffect, useRef } from "react";
import { TonConnectUIProvider, TonConnectButton, useTonWallet, useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Header from './components/Header';
import { Routes, Route, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import Home from './pages/Home'
import ThemeSwitch from './components/ThemeSwitch';
import {Minter} from './pages/Minter';
import { Checker } from './pages/Checker';
import { NftBurning } from './pages/NftBurning';
import { Drop } from './pages/Drop';
import { PopupConnectWallet, PopupContainer, PopupCopied } from './components/General/PopupWindow';
import { addReferral, getAvatar, getIsUserNew, getReferralInfo } from './utils/generalConnections';
import { AccountPage } from './pages/Account/AccountPage';
import Analytics from './Analytics';
import axios from 'axios';
import { ReferralPage } from './pages/Account/ReferralPage';
import { ComingSoonWindow, ModalWindow } from './components/General/ModalWindow';
import { Statistics } from './pages/Statistics';
import { MyNfts } from './pages/MyNfts';
import { FirstOnboarding, Onboarding } from './components/Onboardings/Onboarding';
import { Button } from '@mui/material';






// referral_info = {
    //     "referee_link": referral.referee_link,
    //     "users_referral_link": referral.users_referral_link,
    //     "discount": referral.discount,
    //     "balance": referral.balance,
    //     "commission_percentage": referral.commission_percentage,
    //     "referrals_cnt": referral.referrals_cnt,
    //     "referral_level": referral.referral_level,
    //     "level_threshold": referral.level_threshold,
    // }

//, referralCode, referralLink, referrerCode, discount, balance, referralLevel

const App = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [switchDisabled, setSwitchDisabled] = useState(false); 
  const [popupMessagesMap, setPopupMessagesMap] = useState(new Map())
  const [isTest, setIsTest] = useState(false)
  const [eventHash, setEventHash] = useState(null)

  const [telegramId, setTelegramId] = useState(null)
  const [ifNotSavingData, setIfNotSavingData] = useState(false)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [userAvatarUrl, setUserAvatarUrl] = useState(null)

  const [username, setUsername] = useState(null)
  const location = useLocation();
  // const history = useHistory();
  const [referralCode, setReferralCode] = useState("")
  const [referralLink, setReferralLink] = useState("")
  const [referrerCode, setReferrerCode] = useState("")
  const [discount, setDiscount] = useState(0)
  const [balance, setBalance] = useState(0)
  const [referralLevel, setReferralLevel] = useState(0)
  const [levelTreshold, setLevelTreshold] = useState(1)
  const [referralsCnt, setReferralsCnt] = useState(0)
  const [backButton, setBackButton] = useState(null)
  const [isBackButtonShow, setIsBackButtonShow] = useState(false)
  const [backButtonEvent, setBackButtonEvent] = useState(() => () => {})
  const [isComingSoonOpen, setComingSoonOpen] = useState(false)
  const [isCopyPopupOpen, setIsCopyPopupOpen] = useState(false)
  const [onboardingShow, setOnboardingShow] = useState(false)
  const [isTelegramWebApp, setIsTelegramWebApp] = useState(false);
  const [isWarningNoParamsOpen, setIsWarningNoParamsOpen] = useState(false)
  const [isNewUser, setIsNewUser] = useState(false)

  const PORT = process.env.REACT_APP_API_URL.split(":")[2] ? `:${process.env.REACT_APP_API_URL.split(":")[2]}` : ""
  const BOT = PORT === ":8080" ? "Ton_Application_Bot" : "lidum_bot"
  const APP_NAME = PORT === ":8080" ? "lidum_test" : "app"

  const navigate = useNavigate()
  
  console.log("window.Telegram", window.Telegram)
  const [path, setPath] = useState('/')



  const handleReturnCross = () => {
    if (backButton) {
      backButton.hide();
      console.log("Вернуть крестик")

    }
  }

  useEffect(() => {
    if (backButton) {
      if (isBackButtonShow) {
        backButton.show();
       
      }
      else {
        backButton.hide();
       
      }
     
      console.log("Вернуть/убрать крестик")
    }
  }, [backButton, isBackButtonShow])

  
  // useEffect(() => {

  //   const handleBackButtonClick = () => {
  //     if (backButtonEvent) {
  //         backButtonEvent();
  //     }
  // };
  //   console.log("backButtonEvent", backButtonEvent)
  //   if (backButton && backButtonEvent) {




  //     backButton.onClick(handleBackButtonClick);
  //   }
    
  //       if (buttonRef && buttonRef.current && backButtonEvent) {
  //         const button = buttonRef.current;
  //         button.addEventListener('click', handleBackButtonClick);
  //       }

       

  // }, [backButton, backButtonEvent]

  // )


  // useEffect(() => {
    
  //   const onAccountBackButtonClick = () => {
  //     navigate(-1)
  //   }

  //   const handleBackButtonClick = () => {
  //     if (backButtonEvent) {
  //         backButtonEvent();
  //     }
  // };

  //   console.log("location.pathname", location.pathname)

  //   if (location.pathname.startsWith('/minter') && backButtonEvent) {

  //     if (backButton) {
  //       backButton.onClick(handleBackButtonClick);
  //     }
  //     if (buttonRef && buttonRef.current) {
  //       const button = buttonRef.current;
  //       button.addEventListener('click', handleBackButtonClick)
  //     }
  //     return
  //   }

  //   if (location.pathname.startsWith('/account')) {

  //     if (backButton) {
  //       backButton.onClick(onAccountBackButtonClick);
  //     }
  //     if (buttonRef && buttonRef.current) {
  //       const button = buttonRef.current;
  //       button.addEventListener('click', onAccountBackButtonClick)
  //     }
  //     return
  //   }

   

   
  // }, [backButton, location.pathname, backButtonEvent])

 
  
  useEffect(() => {
    
  

    if (!location.pathname.startsWith('/minter')) {
      setIfNotSavingData(false)
    }
    // else {
    //   setIfNotSavingData(true)
    //   console.log(777777777777)
    // }

   

   
  }, [location.pathname])



  // const handleBackButtonEvent = (onClick) => {
  //   console.log("onCLick", onClick)
  //   if (window.Telegram && window.Telegram.WebApp && backButton) { 
  //     backButton.onClick(function() {
  //       console.log('Кнопка назад нажата');
  //       console.log("onCLick", onClick)
  //       onClick()
  //     });

  //   //   window.Telegram.WebApp.onEvent('backButtonClicked', function() {        
  //   //     console.log('Кнопка назад нажата');
  //   //     onClick()
  //   // });
  //   }
  // }



  useEffect(() => {
   
  

    // const setUserAvatar = async (user_id) => {
    //   const PORT = process.env.REACT_APP_API_URL.split(":")[2] ? `:${process.env.REACT_APP_API_URL.split(":")[2]}` : ""
    //   const TOKEN = PORT === ":8080" ? "7035595418:AAFnoccy-HwiQTNgzqW9Fy6oxTa3TCjpYsk" : "8059123692:AAHbP4GojEaQ9faK3KJfQqzXAXdIeCdZMN4"
    //   try {
    //     const {data} = await axios.post(`https://api.telegram.org/bot${TOKEN}/getUserProfilePhotos`, {
    //       user_id: user_id
    //     });
    //     console.log("setUserAvatar data", data)
    // if (data && data.result) {
    //     console.log(data.result)
    //     console.log("photos", data.result.photos)
    // }
    // } catch (error) {
    //     console.log(error);
    // }
    // }

   

   
   
    

   const getUserReferralInfo = async (telegram_id, username) => {
      const referral_info = await getReferralInfo(telegram_id, username)
      if (referral_info) {
        const referral_link = referral_info.user_referral_link
        setReferralCode(referral_link)
        setReferralLink(`https://t.me/${BOT}/${APP_NAME}?startapp=refer-${referral_link}`)
        setDiscount(referral_info.discount)
        setBalance(referral_info.balance)
        setReferralLevel(referral_info.referral_level)
        setLevelTreshold(referral_info.level_threshold)
        setReferralsCnt(referral_info.referrals_cnt)
        if (referral_info.referee_link) {
          setReferrerCode(referral_info.referee_link)
        }
        
      }
   }

   const handleAddReferal = async (telegram_id, username, referrer_code) => {
    const is_add = await addReferral(telegram_id, username, referrer_code)
    await getUserReferralInfo(telegram_id, username)
  }
   
    const checkIfNewUser = async (telegram_id) => {
      const is_new = await getIsUserNew(telegram_id)
      setIsNewUser(is_new)
    }

    const relativePath = location.pathname;
   
    
    console.log("relativePath", relativePath)
  
    // if (relativePath.startsWith('/burn') || relativePath.startsWith('/checker')) {
    //   return
    // }
    
    console.log("window.Telegram 11111111111111111", window.Telegram)
    // Проверяем доступность Telegram.WebApp
    if (window.Telegram && window.Telegram.WebApp) {
      // var MainButton = window.Telegram.WebApp.MainButton
      // if (MainButton) {
      //   MainButton.show()
      // }
      
      var BackButton = window.Telegram.WebApp.BackButton;
      setBackButton(BackButton)
      
        const initData = window.Telegram.WebApp.initData;
        const params = new URLSearchParams(initData);
        console.log("window.Telegram initData, params", initData, params)
        

      if (!params) {
        setIsWarningNoParamsOpen(true)
        return
      }
     
     

      const paramsMap = new Map();
            for (const [key, value] of params.entries()) {
              paramsMap.set(key, value)
            }
            console.log("paramsArray", paramsMap)
      const userParam = paramsMap.get('user')
      if (!userParam) {
        setIsWarningNoParamsOpen(true)
        return
      }
      setIsWarningNoParamsOpen(false)

      removePopupMessage("no params")

      const userObject = JSON.parse(userParam)

      if (!userObject) {

        return
      }

      console.log("userObject", userObject)
     
      const userId = userObject.id
      setTelegramId(userId.toString())
      setUsername(userObject.username)
      setFirstName(userObject.first_name)
      setLastName(userObject.last_name)
      console.log("userObject.photo_url", userObject.photo_url)
      if (userObject.photo_url) {
        setUserAvatarUrl(userObject.photo_url)
      }
     

      console.log("userId username", userId, userObject.username)
      checkIfNewUser(userId.toString())
     

      // getUserAvatar(userObject.username)

        // Получаем start_param
        const param = params.get('start_param');
        

        console.log("window.Telegram param", param)

        const referrer_code = (param && param.startsWith('refer-')) ? param.replace('refer-', '') : null;

        if (referrer_code) {
          handleAddReferal(userId.toString(), userObject.username, referrer_code)
        }
        else {
          getUserReferralInfo(userId.toString(), userObject.username)
        }

        //setUserReferralInfo(userId.toString(), userObject.username, referrer_code)

        

        

        if (userId && param) {
          
          if (param.startsWith('checker-')) {
            const event_hash = param.replace('checker-', '');
            if (event_hash) {
              navigate(`/checker?event_hash=${event_hash}`)
              return
                
            }
            else {
              navigate('/')
              return
            } 
          }
          if (param.startsWith('minter-')) {
            const event_hash = param.replace('minter-', '');
            if (event_hash) {
              navigate(`/minter?event_hash=${event_hash}`)
              return
                
            } 
            else {
              navigate('/')
              return
            }
          }
        }
       
       

    }
    

}, []);
  
  const [isWalletStatus, setWalletStatus] = useState(null)
   const [chatId, setChatId] = useState(null)


  const [tonConnectUI] = useTonConnectUI();
  

  const wallet = useTonWallet();

  const disconnectHandler = () => {
    if (wallet && !switchDisabled) {
      tonConnectUI.disconnect()
    }
  }

  const connectHandler = async () => {
    if (!wallet && !switchDisabled) {
      await tonConnectUI.openModal()
    }
  }

  const walletAddress = useTonAddress();

  const addPopupMessage = (errorType, errorMessage, errorStatus) => {
    setPopupMessagesMap((prevErrors) => {
        // Создаем новый Map, копируя предыдущие ошибки
        const newErrorsMap = new Map(prevErrors);
        
        // Добавляем новую ошибку или обновляем существующую
        newErrorsMap.set(errorType, {
            status: errorStatus,
            message: errorMessage,
        });
        
        return newErrorsMap; // Возврат нового Map
    });
};

const removePopupMessage = (errorType) => {
  setPopupMessagesMap(prevErrors => {
        const newErrorsMap = new Map(prevErrors);
        newErrorsMap.delete(errorType); // Удаление ошибки по типу
        return newErrorsMap; // Возврат обновленного Map
    });
};

const errorsInMessageMap = () => {
  for (let message of popupMessagesMap.values()) {
    if (message.status === "error") {
      return true
    }
  }
  return false
}

const loadingsInMessageMap = () => {
  for (let message of popupMessagesMap.values()) {
    if (message.status === "loading") {
      return true
    }
  }
  return false
}

const showMessagesAgain = () => {
  const copiedMap = new Map();
    popupMessagesMap.forEach((value, key) => {
        copiedMap.set(key, value);                                    
    });
    setPopupMessagesMap(copiedMap)
}

const handleTestClick = () => {
  setIsTest(!isTest)
}

  useEffect(() => {
    if (isNewUser) {
      setOnboardingShow(true)
    }
  }, [isNewUser])

 
  useEffect(() => {
    const handleConnectionRestored = async () => {
      setWalletStatus("loading")
      const restored = await tonConnectUI.connectionRestored;
      if (restored) {
        setWalletStatus("success");
      } else {
        setWalletStatus("error");
      }
    };

    handleConnectionRestored(); // Вызов функции при монтировании компонента

  }, [tonConnectUI]); // Добавляем tonConnectUI в зависимости
  
 
 // referralCode, referralLink, referrerCode, discount, balance, referralLevel
  return (
    
    <>
      <PopupContainer errors={popupMessagesMap} />


      <FirstOnboarding isOpen={onboardingShow} onClose={() => setOnboardingShow(false)}/>
          <div className="container">
          
          <Header setIsCopyPopupOpen={setIsCopyPopupOpen} isWalletStatus={isWalletStatus} address={walletAddress ? walletAddress : null} onDisconnect={disconnectHandler} handleConnect={connectHandler} switchDisabled={switchDisabled} username={username} firstName={firstName} avatarUrl={userAvatarUrl} ifNotSavingData={ifNotSavingData} setComingSoonOpen={setComingSoonOpen} /> 
          {/* <ThemeSwitch /> */}
          
          <div className='main'>
            
          {/* <PopupConnectWallet status={isWalletStatus} /> */}
          <Analytics />
          <Routes>
            <Route path="/" element={<Home wallet={wallet} searchParams={searchParams} setChatId={setChatId} setIsBackButtonShow={setIsBackButtonShow} setIfNotSavingData={setIfNotSavingData} setComingSoonOpen={setComingSoonOpen} />}  />
            <Route path="/minter" element={<Minter username={username} wallet={wallet} tonConnectUI={tonConnectUI} telegramId={telegramId} isWalletStatus={isWalletStatus} setErrorsMap={setPopupMessagesMap} errorsMap={popupMessagesMap} isTest={isTest} backButton={backButton} setBackButtonEvent={setBackButtonEvent} setIsBackButtonShow={setIsBackButtonShow} setIfNotSavingData={setIfNotSavingData} ifNotSavingData={ifNotSavingData} />}  />
            <Route path="/checker" element={<Checker username={username} wallet={wallet} tonConnectUI={tonConnectUI} setErrorsMap={setPopupMessagesMap} errorsMap={popupMessagesMap} telegramId={telegramId} setIsBackButtonShow={setIsBackButtonShow} setIfNotSavingData={setIfNotSavingData} />} />
            <Route path="/burn" element={<NftBurning wallet={wallet} tonConnectUI={tonConnectUI} setPopupMessagesMap={setPopupMessagesMap} popupMessagesMap={popupMessagesMap} addPopupMessage={addPopupMessage} removePopupMessage={removePopupMessage} errorsInMessageMap={errorsInMessageMap} showMessagesAgain={showMessagesAgain} loadingsInMessageMap={loadingsInMessageMap} telegramId={telegramId} setIfNotSavingData={setIfNotSavingData} />} />
            <Route path="/drop" element={<Drop wallet={wallet} tonConnectUI={tonConnectUI}  setIfNotSavingData={setIfNotSavingData} />}  />
            <Route path="/account" element={<AccountPage setBalance={setBalance} backButton={backButton}   tonConnectUI={tonConnectUI} setBackButtonEvent={setBackButtonEvent} wallet={wallet} username={username} firstname={firstName} avatarUrl={userAvatarUrl} telegramId={telegramId} setIsBackButtonShow={setIsBackButtonShow}  setIfNotSavingData={setIfNotSavingData} isTest={isTest} balance={balance}  setPopupMessagesMap={setPopupMessagesMap} popupMessagesMap={popupMessagesMap} addPopupMessage={addPopupMessage} removePopupMessage={removePopupMessage} errorsInMessageMap={errorsInMessageMap} showMessagesAgain={showMessagesAgain} loadingsInMessageMap={loadingsInMessageMap} />}  />
            <Route path="/account/referral-program" element={<ReferralPage setIsCopyPopupOpen={setIsCopyPopupOpen} wallet={wallet} username={username} backButton={backButton}   setBackButtonEvent={setBackButtonEvent} firstname={firstName} avatarUrl={userAvatarUrl}  telegramId={telegramId} referralCode={referralCode} referralLink={referralLink} referrerCode={referrerCode} discount={discount} balance={balance} referralLevel={referralLevel} setReferrerCode={setReferrerCode} levelTreshold={levelTreshold} referralsCnt={referralsCnt} setDiscount={setDiscount} setIsBackButtonShow={setIsBackButtonShow} setIfNotSavingData={setIfNotSavingData} setReferralLevel={setReferralLevel} setLevelTreshold={setLevelTreshold} setReferralsCnt={setReferralsCnt}  />}   />
            <Route path="/statistics" element={<Statistics setIfNotSavingData={setIfNotSavingData} setIsBackButtonShow={setIsBackButtonShow} telegramId={telegramId} username={username} />}   />
            <Route path="/my-nfts" element={<MyNfts username={username} setIsBackButtonShow={setIsBackButtonShow} setIfNotSavingData={setIfNotSavingData} firstname={firstName} telegramId={telegramId} />}   />
         
          </Routes>     
          </div>
          
          {/* {!(telegramId || PORT === ":8080") &&
              (
                <button type='button' ref={buttonRef}>Назад</button>
              )
          } */}
         


          {(PORT === ":8080") && (
            <>
              <Button variant='contained' sx={{margin: "25px 0", display: "block"}} onClick={() => setOnboardingShow(true)}>Показать онбординг</Button>
            <Button variant='contained' className='btn' onClick={handleTestClick} id="test-button" style={{backgroundColor: `${isTest ? "green" : "rgb(var(--element))"}`}}>test</Button>
            </>
          )

          }
          
          
          
          </div>
          <ComingSoonWindow isOpen={isComingSoonOpen} setIsOpen={setComingSoonOpen}/>
          <PopupCopied isOpen={isCopyPopupOpen} setIsOpen={setIsCopyPopupOpen} />
          <ModalWindow isOpen={isWarningNoParamsOpen} message={"Your session has expired or user information could not be retrieved. Please refresh the page"} onClick={() => setIsWarningNoParamsOpen(false)}/>
          </>
  );
}
export default App;

