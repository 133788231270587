import './Header.css';
import SwitchToggle from './Header/SwitchToggle';
import logo from '../img/logo.png'

import { PopupConnectWallet } from './General/PopupWindow';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HeaderPopupMenu } from './Header/HeaderPopupMenu';
import { useState } from 'react';
import { WarningModal, WarningModalForPath } from './General/ModalWindow';




const Header = ({ address, onDisconnect, handleConnect, switchDisabled, username, firstName, avatarUrl, ifNotSavingData, setComingSoonOpen, setIsCopyPopupOpen }) => {

    const [isWarningOpen, setIsWarningOpen] = useState(false)
    const navigate = useNavigate()
    const [path, setPath] = useState('/')

    

    const logoClick = () => {
        console.log("logoClick", ifNotSavingData)
        if (ifNotSavingData) {
            setPath('/')
            setIsWarningOpen(true)
            return
        }
        setIsWarningOpen(false)
        navigate("/")
    }
    return (
        <>
        <header className="header">
           
            <div className="logo no-select" onClick={logoClick}><img width={23} height={28} src={logo} alt="logo" />Lidum app</div>
            <SwitchToggle address={address} onDisconnect={onDisconnect} handleConnect={handleConnect} switchDisabled={switchDisabled} setIsCopyPopupOpen={setIsCopyPopupOpen} />
            <HeaderPopupMenu username={firstName}  avatarUrl={avatarUrl} ifNotSavingData={ifNotSavingData} setIsWarningOpen={setIsWarningOpen} setPath={setPath} setComingSoonOpen={setComingSoonOpen} />
           
        </header>
        <WarningModalForPath isModalOpen={isWarningOpen} setIsModalOpen={setIsWarningOpen} path={path} />
       
        </>
    )
}

export default Header