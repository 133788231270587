import axios from "../axios";

export const getMyNftsData = async (telegram_id) => {
    const sending_json = {
        telegram_id: telegram_id
    }
    try {
        const {data} = await axios.post(`/api/get_nfts_info/`, sending_json)
        if (data.status === "success") {
            return data.my_nfts
        }
        return null
    } catch (error) {
        console.log(error)
        //return placeholder;
        //return avatarPlaceholder;
        return null
    }
}