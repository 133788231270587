import React, { useEffect, useRef, useState, useCallback } from "react";
import { Link, Navigate, Route, Routes, useNavigate, useSearchParams } from "react-router-dom"
import "./Minter.css"
import placeholder from "../img/loadImagePlaceholder.png"
import avatarPlaceholder from "../img/imagePlaceholder.png"
import { MinterFormTextValidator } from "../validators/minterFormsValidator";
import qrPlaceholder from "../img/qr-placeholder.jpg"
import NumberSelector, { TimeZoneSelector } from "../components/Minter/NumberSelector";
import DateTimeInput from "../components/Minter/DateTimeInput";
import { checkSubscription, checkSubscriptionsNew, getCurrentDatetimeLocal, isBase64Image } from "../utils/minterUtils";
import tonToUSD from "../utils/tonToUSD";
import { TonConnectButton } from "@tonconnect/ui-react";
import { getComission, getEventInfoForMinter, getRandomImage, sendDataToBot, sendMinterData } from "../utils/minterConnections";
import QRCode from "qrcode.react";
import { SubscriptionsInputBox } from "../components/Minter/SubscriptionsInputBox";
import { PopupFormErrorMessage, PopupMinter, PopupTransactionFailed, PopupConnectWallet, PopupTransactionProcess, PopupContainer } from "../components/General/PopupWindow";
import { addTransaction, createTransaction, getApiWallet, getAuthorInfo, getAvatar, getAvatarsArr, getCollectionInfo, getIsCollectionNameUnique, getTransactionStatus } from "../utils/generalConnections";
import { IfWalletNotConnected, NoAccessPage } from "../components/General/IfWalletNotConnected";
import TonWeb from "tonweb";
import { CheckboxComponent } from "../components/Minter/CheckboxComponent";
import { addElemToUseStateArr, getCurrentDate, removeElemFromUseStateArr } from "../utils/appUtils";
import { MakePostResultWindow, ShowedWarningModalWindow, WarningModal } from "../components/General/ModalWindow";
import { DateTimeBox } from "../components/Minter/DateTimeBox";
import CalendarInput from "../components/Minter/CalendarInput";
import CalendarsBox from "../components/Minter/CalendarsBox";
import { NewSubscriptionsInputBox } from "../components/Minter/NewSubscriptionsInputBox";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import { MuiCalendar } from "../components/Minter/MuiCalendar";
import { MuiCalendarsBox } from "../components/Minter/MuiCalendarsBox";
import { InputField, TextareaField } from "../components/General/InputField";
import { NewLoadImage } from "../components/Minter/NewLoadImage";

import eventLogoPlaceholder from "../img/logo_examples/eventlogo1.png"
import collectiontLogoPlaceholder from "../img/logo_examples/eventlogo2.png"



export const Minter = ({wallet, tonConnectUI, telegramId, setErrorsMap, errorsMap, username, isTest, setIsBackButtonShow, setBackButtonEvent, setIfNotSavingData, ifNotSavingData, backButtonRef}) => {

    const [searchParams, setSearchParams] = useSearchParams();
    //const minter_chat_id = searchParams.get("chat_id") ? searchParams.get("chat_id") : null;

    const collectionNameDefaultText = "Enter a name"
    const eventNameDefaultText = "Enter a name"
    const descriptionDefaultText = "Enter a description of the event"
    const countDefaultText = "Count of your NFT"
    const passwordDefaultText = "Password"
    const buttonDefaultText = "Button"
    const messageForUserDefaultText = "Type something"
    const descriptionOfYourPostDefaultText = "Description of your post"

    const navigate = useNavigate()
    const [eventId, setEventId] = useState(null)
    const [hashesArr, setHashesArr] = useState([])
    const [onlyView, setOnlyView] = useState(false)
    const [eventStatus, setEventStatus] = useState(null)
    const [timeZone, setTimeZone] = useState(null)
    const [isOpenCollectionNameWarning, setIsOpenCollectionNameWarning] = useState(false)
    const [isConfirmCollectionName, setIsConfirmCollectionName] = useState(false)
    const [closeAppWindowVisible, setCloseAppWindowVisible] = useState(false)
    const [showedWarning, setShowedWarning] = useState(false)
    const [firstPageErrorsArr, setFirstPageErrorsArr] = useState([])
    const [secondPageErrorsArr, setSecondPageErrorsArr] = useState([])
    const [thirdPageErrorsArr, setThirdPageErrorsArr] = useState([])
    
    const [errorIndices, setErrorIndices] = useState([]);
    const PORT = process.env.REACT_APP_API_URL.split(":")[2] ? `:${process.env.REACT_APP_API_URL.split(":")[2]}` : ""
    
    const [isSubscriptionsChecked, setSubscriptionsChecked] = useState(false)
    const [nameValidationError, setNameValidationError] = useState(false)
    const [descriptionValidationError, setDescriptionValidationError] = useState(false)
    const [descriptionOfYourPostValidationError, setDescriptionOfYourPostValidationError] = useState(false)
    const [userMessageValidationError, setUserMessageValidationError] = useState(false)
    const [lastFocusIndex, setLastFocusIndex] = useState(null)

    const [isChannelErrors, setIsChannelErrors] = useState(false)
    
    const BOT = PORT === ":8080" ? "Ton_Application_Bot" : "LidumAppBot"
    const APP_NAME = PORT === ":8080" ? "lidum_test" : "app"

    const REQUIRED_CHANNEL = PORT === ":8080" ? "@lidum_test" : "@lidumtma"


   // const initial_subscriptions = "@[channel1], @[channel2],..."

    const initial_subscriptions = [REQUIRED_CHANNEL]

    const initialValues = {
        name: "",
        description: "",
        count: "",
        start: "",
        end: "",
        password: "",
        subscriptions: initial_subscriptions,
        descriptionOfYourPost: "",
        button: "",
        file_name: "",
        file_base64: "",
    };

   const [comission, setComission] = useState(null)
   const [comissionToUSD, setComissionToUSD] = useState(null)
   const [apiWallet, setApiWallet] = useState(null)
   const descriptionRef = useRef(null);
   const eventNameRef = useRef(null);
   const collectionNameRef = useRef(null);
   const passwordRef = useRef(null);
   const buttonRef = useRef(null);
   const submitButtonRef = useRef(null)
   
   const countRef = useRef(null);
   const descriptionOfYourPostRef = useRef(null); 
   const [eventName, setEventName] = useState("")
   const [collectionImageName, setCollectionImageName] = useState(null)
   const [eventImageName, setEventImageName] = useState(null)
   const [notVerifiedSubscriptions, setNotVerifiedSubscriptions] = useState([])
   const [channelNotExistIndices, setChannelNotExistIndices] = useState([])
   const [channelInvalidFormatIndices, setChannelInvalidFormatIndices] = useState([])
   const [channelDublicateIndices, setChannelDublicateIndices] = useState([])
   const [successSubscriptions, setSuccessSubscriptions] = useState([])
   const [channelAvatars, setChannelAvatars] = useState(new Map())
   
   const [isQrChecked, setIsQrCheched] = useState(false)

   const [sendStatus, setSendStatus] = useState(null)

   const [readOnly, setReadOnly] = useState(false)
   
   const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
   const [keyboardHeight, setKeyboardHeight] = useState(0);
   const [viewportHeight, setViewportHeight] = useState(window.visualViewport.height);

   const [isInputFocused, setIsInputFocused] = useState(false);
   const [isCountFocused, setIsCountFocused] = useState(false)

    const [initialEventHash, setInitialEventHash] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [selectedCollectionImageUrl, setSelectedCollectionImageUrl] = useState(null)
    const [selectedEventImageUrl, setSelectedEventImageUrl] = useState(null)
    
    // const [qrCodeLinks, setQRCodeLinks] = useState([`https://lidum.ru${PORT}/checker/?event_id=1`, `https://lidum.ru${PORT}/checker/?event_id=2`, `https://lidum.ru${PORT}/checker/?event_id=3`])
    const [base64QRCodes, setBase64QRCodes] = useState([]);
    const [qrCodeLinks, setQRCodeLinks] = useState([])
  

    
    

    const [collectionNameErrorMessage, setCollectionNameErrorMessage] = useState(null)
   
    const [name, setName] = useState("")
    const [collectionMetaName, setCollectionMetaName] = useState("")
    const [description, setDescription] = useState("")
    const [count, setCount] = useState("")
    const [start, setStart] = useState(initialValues.start)
    const [end, setEnd] = useState(initialValues.end)
    const [password, setPassword] = useState("")
    const [subscriptions, setSubscriptions] = useState(initialValues.subscriptions)
    // const [invite, setInvite] = useState(initialValues.invite)
    const [descriptionOfYourPost, setDescriptionOfYourPost] = useState("")
    const [button, setButton] = useState("")
    const [messageForUser, setMessageForUser] = useState("")
    const [isTransactionError, setIsTransactionError] = useState(null)
    const [transactionId, setTransactionId] = useState(null)
    const [intervalId, setIntervalId] = useState(null);

    const [transactionStatus, setTransactionStatus] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [isEventLogoSelected, setIsEventLogoSelected] = useState(true)

    // setErrorsMap(new Map())

    const onBackButtonClick = useCallback((pageNumber) => {
        // Если есть event_hash в параметрах и мы на первой странице
        if (searchParams.get("event_hash") && pageNumber === 1) {
            navigate(-1); // Возвращаемся на предыдущую страницу (Statistics)
            return;
        }
        
        // Существующая логика
        if (pageNumber === 1 && !onlyView && !eventStatus) {
            setIsModalOpen(true);
            return;
        }
     
        if (pageNumber === 1 && onlyView) {
            navigate(-1);
            return;
        }
        
        if (eventStatus) {
            navigate(-1);
            return;
        }
        
        if (!wallet) {
            setIsModalOpen(true);
            return;
        }

        setIsModalOpen(false);
        setPageNumber(pageNumber - 1);    
        window.scrollTo(0, 0);
    }, [searchParams, onlyView, eventStatus, wallet, navigate, setIsModalOpen, setPageNumber]);

    useEffect(() => {
        console.log("eventStatus, onlyView, pageNumber, eventStatus || (onlyView && pageNumber !== 3)", eventStatus, onlyView, pageNumber, (eventStatus || (onlyView && pageNumber !== 3)))
        if (eventStatus || (onlyView && pageNumber !== 3)) {
            setIfNotSavingData(false)
        }
        else {
            setIfNotSavingData(true)
        }
     
    }, [eventStatus, onlyView, pageNumber])

  
    
    useEffect(() => {
        const handleBackButton = () => onBackButtonClick(pageNumber);
        
        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButton);
        }
        
        return () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButton);
            }
        };
    }, [pageNumber, ifNotSavingData, onlyView, wallet, eventStatus, searchParams, onBackButtonClick]);

    // useEffect(() => {
    //     if (backButtonRef && backButtonRef.current) {
    //         backButtonRef.current.addEventListener('click', () => onBackButtonClick(pageNumber))
    //     }
    //     return () => {
    //         if (backButtonRef.current) {
    //             backButtonRef.current.removeEventListener('click', () => onBackButtonClick(pageNumber))
    //         }
           
        
    //     }
        
    // }, [backButtonRef.current, pageNumber, ifNotSavingData])

    useEffect(() => {
        setErrorsMap(new Map())      
        setIsBackButtonShow(true)
        console.log("start use effect pageNumber", pageNumber)
    }, []);

    useEffect(() => {
        if (transactionStatus === "error") {
            setIsTransactionError(true)
        }
        else {
            setIsTransactionError(false)
        }
       
    }, [transactionStatus]);
    
    useEffect(() => {

        const fetchData = async () => {
            if (!searchParams.get("event_hash")) {
                const newChannelAvatars = new Map()
                try {
                    const avatarUrl = await getAvatar(REQUIRED_CHANNEL)
                    if (avatarUrl) {                       
                        newChannelAvatars.set(REQUIRED_CHANNEL, avatarUrl)
                    }
                    else {
                        newChannelAvatars.set(REQUIRED_CHANNEL, avatarPlaceholder)
                    }
                    setChannelAvatars(newChannelAvatars)
                } catch (error) {
                    console.log(error)
                    newChannelAvatars.set(REQUIRED_CHANNEL, avatarPlaceholder)
                    setChannelAvatars(newChannelAvatars)
                }
                return
            }           
            try {
                const event_hash = searchParams.get("event_hash")
                setTransactionStatus("success")
                var data = await getEventInfoForMinter(event_hash)
                // if (!data) {
                //     data = {
                //         start_date: `2024-09-14T02:30`,
                //         end_date: `2024-09-23T02:37`,
                //         collection_name: "Example collection name",
                //         event_name: "Example event Name",
                //         subscriptions: ["@rasfocuse", "@FarmerDao", "@channel1"],
                //         nfts_cnt: 9,
                //         collection_logo_url: collectiontLogoPlaceholder,
                //         event_logo_url: eventLogoPlaceholder,
                //         description: "Example event description",
                //         image_name: "image_name.jpg",
                //         user_timezone: 7,
                //         password: ""
            
                //     }
                // }
                console.log("event_hash event info data", data)
                setInitialEventHash(event_hash)
                setHashesArr([event_hash])
                setOnlyView(true)
                setCount(data.nfts_cnt)
                setEventName(data.event_name)
                setName(data.collection_name)
                setDescription(data.description)
                setSelectedEventImageUrl(data.event_logo_url)
                setStart(data.start_date)
                setEnd(data.end_date)   
                setTimeZone(data.user_timezone) 
                setMessageForUser(data.user_msg)
                if (data.empty_password) {
                    setPassword("")
                }
                else {
                    setPassword("*****")
                }
               
                const avatars_url = await getAvatarsArr(data.subscriptions)
                const newChannelAvatars = new Map()
                const newSubscriptions = []
                for (let i=0; i<avatars_url.length; i++) {
                    if (avatars_url[i]) {
                        newChannelAvatars.set(data.subscriptions[i], avatars_url[i])
                        newSubscriptions.push(data.subscriptions[i])
                    }
                    
                }
                setSubscriptions(newSubscriptions)
                //console.log("newChannelAvatars", newChannelAvatars)
                setChannelAvatars(newChannelAvatars)
                
            } catch (error) {
                console.log(error)
                setEventStatus("Event not found")
                // setSubscriptions(["@lidumapp"])
                // const newChannelAvatars = new Map()
                // newChannelAvatars.get(0, )
            }

            // collection_name: "Collection Name",
            // event_name: "Event Name",
            // description: "123123123",
            // logo: placeholder,
            // start_date: `2024-09-14T02:30`,
            // end_date: `2024-09-18T18:39`,
            // invites: 0,
            // subscriptions: ["@rasfocuse", "@FarmerDao", "@channel1"],
            // subscriptions_avatar_urls: [placeholder, placeholder, placeholder],
            // nfts_cnt: 9,
        };

        fetchData();
       
    }, []);


    useEffect( () => {
        if (hashesArr.length > 0) {
            const links = getQRCodeLinks();
            setQRCodeLinks(links);
            
            // Добавляем небольшую задержку для генерации QR-кодов
            setTimeout(() => {
                const canvasElements = document.querySelectorAll('.qr-code-canvas');
                const images = Array.from(canvasElements).map(canvas => {
                    return canvas.toDataURL("image/png");
                });
                setBase64QRCodes(images);
            }, 500);
        }
    }, [hashesArr]);
   
    
    const handleComission = async (collection_cnt) => {

        const result = await getComission({
            telegram_id: telegramId,
            collection_images_cnt: Number(collection_cnt)
        })
        if (result) {
            setComission(result)
            const response = await tonToUSD(result);
            if (response) {
                setComissionToUSD(response)
            }
        }
        
    }
    const handleConnect = async () => {
        try {
            await tonConnectUI.openModal()
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

   

    const addFirstPageErrorFromArr = (name) => {
        addElemToUseStateArr({ setArr: setFirstPageErrorsArr, elem: name });
    }  

    const addSecondtPageErrorFromArr = (name) => {
        addElemToUseStateArr({ setArr: setSecondPageErrorsArr, elem: name });
    }
    
    const addThirdPageErrorFromArr = (name) => {
        addElemToUseStateArr({ setArr: setThirdPageErrorsArr, elem: name });
    }
   
    const removeFirstPageErrorFromArr = (name) => {
        const newFirstPageErrorsMap = firstPageErrorsArr.filter(item => item !== name);
        setFirstPageErrorsArr(newFirstPageErrorsMap)
    }  
    
    const removeSecondPageErrorFromArr = (name) => {
        const newSecondPageErrorsMap = secondPageErrorsArr.filter(item => item !== name);
        setSecondPageErrorsArr(newSecondPageErrorsMap)
    }   
    
    const removeThirdPageErrorFromArr = (name) => {
        const newThirdPageErrorsMap = thirdPageErrorsArr.filter(item => item !== name);
        setThirdPageErrorsArr(newThirdPageErrorsMap)
    }

    const ArrowBox = ({ pageNumber }) => {

        return (

            <div className="arrow-box">
                <BackArrowButton pageNumber={pageNumber} />
                <NextArrowButton pageNumber={pageNumber} />
            </div>

        )
    }

    const addNotVerifiedSubscription = (index) => {
        addElemToUseStateArr({ setArr: setNotVerifiedSubscriptions, elem: index });
    };

    const removeNotVerifiedSubscription = (index) => {     
        if (!notVerifiedSubscriptions.includes(index)) {
            return
        }
        const newVerifiedSubscriptions = notVerifiedSubscriptions.filter(item => item !== index);
        setNotVerifiedSubscriptions(newVerifiedSubscriptions)
    };

    

    const addSubscription = (newSubscription) => {
        addElemToUseStateArr({ setArr: setSubscriptions, elem: newSubscription });
    };

    const removeSubscription = (indexToRemove) => {
        removeElemFromUseStateArr({ setArr: setSubscriptions, arr: subscriptions, index_1: indexToRemove });
        setErrorIndices((prev) => prev.filter((index) => index !== indexToRemove));
    };

  
    const replaceSubscription = (newValue, index) => {
        const newSubscriptions = [...subscriptions]; // Создаем новый массив
        newSubscriptions[index] = newValue; // Обновляем подписку по индексу
        setSubscriptions(newSubscriptions); // Устанавливаем новое значение массива
    };

    


    function findLastDuplicateIndex(arr) {
        const seen = new Set();
        let lastIndex = -1;
    
        for (let i = arr.length - 1; i >= 0; i--) {
            if (seen.has(arr[i])) {
                lastIndex = i; // Запоминаем индекс последнего дубликата
                break; // Выходим из цикла, если нашли дубликат
            }
            seen.add(arr[i]);
        }
    
        return lastIndex; // Вернем индекс последнего дублирующегося элемента
    }

    const onSubscriptionFocus = (index) => {
        // Удаляем индекс ошибки из состояния, если он есть
        setErrorIndices((prev) => prev.filter((i) => i !== index));
        setChannelInvalidFormatIndices((prev) => prev.filter((i) => i !== index));
        setChannelNotExistIndices((prev) => prev.filter((i) => i !== index));
        setChannelDublicateIndices((prev) => prev.filter((i) => i !== index));
        setSuccessSubscriptions((prev) => prev.filter((i) => i !== index))
        // if (channelAvatars.get(index)) {
        //     removeChannelAvatar(index)
        // }
        
    };

    const handleSubmitSubscriptions = async () => {
        const newErrorIndices = [];
        const newChannelNotExistIndices = [];
        const newChannelDublicateIndices = [];
        const newChannelInvalidFormatIndices = [];
        const newSuccessSubscriptions = []

        const newChannelAvatars = new Map()

        setSubscriptionsChecked(true)
        setLastFocusIndex(null)

        
    for (let index = 0; index < subscriptions.length; index++) {
        const value = subscriptions[index];
        
        const formatted_subscription = checkSubscription(value);

        if (!formatted_subscription) {
            newErrorIndices.push(index);
            newChannelInvalidFormatIndices.push(index)
        }
        else {
            
               
           

         const avatarUrl = await getAvatar(formatted_subscription); // Проверка существования канала
            //const avatarUrl = true
            if (!avatarUrl) {
                newErrorIndices.push(index);
                newChannelNotExistIndices.push(index)
                continue
            }
            newChannelAvatars.set(formatted_subscription, avatarUrl)
            const countValue = subscriptions.filter(item => item === formatted_subscription).length;
            if (countValue > 1) {
                setErrorIndices((prev) => [...prev, index]);
                newErrorIndices.push(index)    
                newChannelDublicateIndices.push(index)
            }
            else {
                newSuccessSubscriptions.push(index)
            }

            const newSubscriptions = [...subscriptions]; // Создаем новый массив
            newSubscriptions[index] = formatted_subscription; // Обновляем подписку по индексу
            setSubscriptions(newSubscriptions); // Устанавливаем новое значение массива
        }        
    }
        setSuccessSubscriptions(newSuccessSubscriptions)
        setErrorIndices(newErrorIndices);
            setChannelDublicateIndices(newChannelDublicateIndices)
            setChannelNotExistIndices(newChannelNotExistIndices)
            setChannelInvalidFormatIndices(newChannelInvalidFormatIndices)
        setChannelAvatars(newChannelAvatars)
        // Если есть ошибки, обновляем состояние errorIndices
        if (newErrorIndices.length > 0) {
            
            //setFormErrorMessage('Subscriptions input error! Channels do not exist or are repeated')
            return false; // Не отправляем форму, если есть ошибки
        }
        return true
    }

    const handleFormSubmit = async (e, pageNumber) => {
        e.preventDefault();
        if (!wallet) {
            await handleConnect();
            return
        }
        
        // if (pageNumber !== pagesArr.length - 1) {
           
        //     setPageNumber(pageNumber + 1)
        // }
        if (onlyView) {
            if (pageNumber !== pagesArr.length - 1) {
                setPageNumber(pageNumber + 1)
                window.scrollTo(0, 0);
            }
            return
        }
        if (pageNumber === 1) {
            if (isConfirmCollectionName) {
                await handleFirstSubmit(e)
            }
            else {
                setIsOpenCollectionNameWarning(true)
            }   
            
        }
        if (pageNumber === 2) {
            
            await handleSecondSubmit(e)
        }
    }

    const checkAvatars = () => {
        for (let i = 0; i < subscriptions.length; i++) {
            const element = subscriptions[i];
            if (!channelAvatars.get(element)) {
                return false
            }
            
        }
        return true
   }

    const checkSecondValidation = () => {
        if (onlyView) {
            return true
        }
        if (timeZone === null || !start || !end) {
            return false
        }

        const startDate = new Date(start);
        const endDate = new Date(end);
        const currentDate = getCurrentDate(timeZone)
        if (startDate < currentDate) {
           

           return false
            
        }
        if (endDate <= startDate) { 
            
            return false
            
            
        }
        if (!checkAvatars()) {
            return false
        }
        
        
        return true
    }

    
    const handleSecondSubmit  = async (e) => {
        e.preventDefault();
       
        var newErrors = new Map()

        if (timeZone === null) {
            newErrors.set("timezone", {
                status: "error",
                message: "Select time zone"
            })
        }

        
        
        const isCorrectSubscriptions = await handleSubmitSubscriptions()
        console.log("isCorrectSubscriptions", isCorrectSubscriptions)
        if (!isCorrectSubscriptions) {

            newErrors.set("subscriptions", {
                status: "error",
                message: "Subscriptions input error! Channels do not exist or are repeated"
            })

          
            
        }

        if (!start || !end) {
           
           newErrors.set("noDate", {
            status: "error",
            message: "No start or end date specified!"
        })

            
        }

        

        const startDate = new Date(start);
        const endDate = new Date(end);
        const currentDate = getCurrentDate(timeZone)
        if (startDate < currentDate) {
           

            newErrors.set("startDate", {
                status: "error",
                message: "The start date must be later than the current time!"
            })
            
        }
        if (endDate <= startDate) { 
            
            newErrors.set("endDate", {
                status: "error",
                message: "The start date is greater than the end date!"
            })
            
            
        }
       
        if (newErrors.size > 0) {
            setErrorsMap(newErrors)
            setTimeout(() => {
                setErrorsMap(new Map()); // Очистка ошибок через 3 секунды
            }, 3000);
            return
        }
        setErrorsMap(new Map())


        await sendDataToServer()
    }

    

    const checkFirstValidation = () => {
        if (onlyView) {
            return true
        }
        if (!selectedCollectionImageUrl || !selectedEventImageUrl) {
            return false
        }
        if (!name || !eventName || !count || !description) {
            return false
        }
        if (nameValidationError || descriptionValidationError) {
            return false
        }
        return true
    }

   
    const handleFirstSubmit = async (e) => {
        e.preventDefault();
        // if (pageNumber !== pagesArr.length - 1) {
        //     setPageNumber(2)
        // }
    
        var newErrors = new Map()

       
        if (!selectedCollectionImageUrl) {            
            //setFormErrorMessage("Select an image!")
            newErrors.set("image", {
                status: "error",
                message: "Select an collection image!"
            })
 
        }
        if (!selectedEventImageUrl) {            
            //setFormErrorMessage("Select an image!")
            newErrors.set("image", {
                status: "error",
                message: "Select an event image!"
            })
 
        }
     
        const is_collection_name_unique = await checkIsCollectionNameUnique(name)
        if (!is_collection_name_unique) {
            newErrors.set("name_unique", {
                status: "error",
                message: "Collection name is already taken"
            })
        }   

        if (nameValidationError) {
            //setFormErrorMessage("Collection name validation error! Only English letters are allowed")
            newErrors.set("name", {
                status: "error",
                message: "Collection name validation error! Only English letters are allowed"
            })
                    
        }

        if (descriptionValidationError) {
            //setFormErrorMessage("Collection name validation error! Only English letters are allowed")
            newErrors.set("description", {
                status: "error",
                message: "Description is empty!"
            })
                    
        }

        if (newErrors.size > 0) {
            setErrorsMap(newErrors)
            setTimeout(() => {
                setErrorsMap(new Map()); // Очистка ошибок через 3 секунды
            }, 3000);
            return
        }
        setErrorsMap(new Map())
        setIsConfirmCollectionName(true)
        if (transactionStatus !== "success") {
            await handleComission(count);
        }
        else {
            
            setComission(0)
            setComissionToUSD(0)
        }

        setPageNumber(2)
        window.scrollTo(0, 0);
    }

   

    const setKeyboardMargin = () => {
        if (pageNumber === 1) {
            document.getElementById("first-page-container").style.marginBottom = "125px"
        }
        
    }

    const resetKeyboardMargin = () => {
        if (pageNumber === 1) {
            document.getElementById("first-page-container").style.marginBottom = "0px"
        }
        
    }
    

    const handleAppearanceKeyboard = (ref, isScroll) => {
        if (onlyView && !isScroll) return
        if (!ref || !ref.current) return
        // document.getElementById("first-page-container").style.marginBottom = "125px"
        // Прокручиваем с учетом высоты клавиатуры
        const currentKeyboardHeight = 205
        // setTimeout(() => {
        //     const { top } = ref.current.getBoundingClientRect();
        //     const scrollOffset = top + window.scrollY - currentKeyboardHeight;
        //     console.log("top, scrollOffset, currentKeyboardHeight", top, scrollOffset, currentKeyboardHeight)
        //     // Уменьшите значение высоты на 20-30 пикселей (например 20)
        //     window.scrollTo({
        //       top: scrollOffset, // Уменьшение наперед
        //       behavior: 'smooth',
        //     });
        //   }, 400);

          const scroll = () => {
            const { top } = ref.current.getBoundingClientRect();
            const scrollOffset = top + window.scrollY - currentKeyboardHeight;
 
            window.scrollTo({
                top: scrollOffset,
                behavior: 'smooth',
            });
        };

        requestAnimationFrame(() => {
            // Убедитесь, что вызываем только после полной отрисовки
            setTimeout(scroll, 400); // Можно использовать setTimeout для того, чтобы вызвать в следующей "пачке"
        });

      };



    //   const handleAppearanceKeyboard = (ref) => {
    //     setIsInputFocused(true)
    //     setTimeout(() => {
    //       const { top } = ref.current.getBoundingClientRect();
    //       const scrollOffset = top + window.scrollY - (window.innerHeight - viewportHeight);
    
    //       // Добавьте запас прокрутки
    //       window.scrollTo({
    //         top: scrollOffset + 200,
    //         behavior: 'smooth',
    //       });
    //     }, 400); // Задержка для полной загрузки клавиатуры
    //   };

   

    const handleFocusCount = (e) => {
   
        document.getElementById("count").classList.remove("check-input")
        if (e.target.value === "count") {
            setCount(""); 
        }
    };

    const handleBlurCount = (e) => {

        document.getElementById("count").classList.add("check-input")
        if (e.target.value === "") {
            setCount("count");
        }
    };

    const handleBlurInput = (e, elem_id) => {
        if (e.target.value !== "") {
            document.getElementById(elem_id).classList.add("check-input")
        }
        
        
    }

    const handleFocusInput = (elem_id) => {
        document.getElementById(elem_id).classList.remove("check-input")
    }

   

    const handleAddTransaction = async (transaction_hash, event_id) => {
        const wallet_address = tonConnectUI.account.address
        const transaction_id = await addTransaction(transaction_hash, comission, wallet_address, event_id)
        if (transaction_id) {
            setTransactionId(transaction_id)
            return transaction_id
        }
        
        return null
    }

    const handleCreateTransaction = async (event_id) => {
        try {
           
            const transaction = await createTransaction(comission)
            
            const response = await tonConnectUI.sendTransaction(transaction);
            if (!response || !response.boc) {
                throw new Error("Не удалось получить ответ от блока");
            }
            
            const boc_cell = await TonWeb.boc.Cell.oneFromBoc(TonWeb.utils.base64ToBytes(response.boc)).hash();  
           
            const transaction_hash = TonWeb.utils.bytesToBase64(boc_cell);
           
            setTransactionStatus("loading")
            addError("transaction", "Transaction in progress...", "loading")
            const transaction_id = await handleAddTransaction(transaction_hash, event_id)
            
            if (!transaction_id) {
                setTransactionStatus("error")  
                addError("transaction", "There was an error processing the transaction. Please try again", "error")           
                return null
            }
            return transaction_id
        } catch (error) {
            setTransactionStatus("error")
            addError("transaction", "There was an error processing the transaction. Please try again", "error")           
           
            return null
        }


    }  
    
    const waitForTransactionSuccess = (transaction_id) => {
        return new Promise((resolve, reject) => {
            const id = setInterval(() => {
                getTransactionStatus({ transaction_id, setTransactionStatus, addError, removeError }).then(status => {
                    if (status === "success") {
                        clearInterval(id);
                        setIntervalId(null)
                        resolve("success");
                    } else if (status === "error") {
                        clearInterval(id);
                        setIntervalId(null)
                        reject(new Error("Transaction failed"));
                    }
                });
            }, 5000);
        });
    }

    const getBase64ImageExtention = (base64Image) => {
        const [header, base64Data] = base64Image.split(',');

       
        if (header.startsWith('data:')) {
            const mimeType = header.match(/:(.*?);/)[1]; // Извлекаем MIME-тип

            let extension;

            switch (mimeType) {
                case 'image/png':
                    extension = 'png';
                    break;
                case 'image/jpeg':
                    extension = 'jpg';
                    break;
                default:
                    extension = '';
                    break;
            }

               
                return extension;
            } else {
                //console.log('Не удалось определить тип данных.');
                return "";
            }
    }

  
    const checkCollectionNameValidation = (e) => {
        const pattern = /^[A-Za-z0-9\s-_,.!?'"() @#$%^&*]*$/;

        if (!pattern.test(e.target.value)) {           
            setNameValidationError(true)
            setCollectionNameErrorMessage("Only English letters are allowed")
            return e.target.value === ""
        }
        setNameValidationError(false)
        setCollectionNameErrorMessage(null)
        if (errorsMap.get("name")) {
            removeError("name")
        }
        return true
    }

    const checkIsCollectionNameUnique = async (value) => {
        const collectionNameReadOnly = (readOnly || transactionStatus === "success" || onlyView)
        if (collectionNameReadOnly) {
            return true
        }
        const is_unique = await getIsCollectionNameUnique(value)
        if (!is_unique) {
            setCollectionNameErrorMessage("Collection name is already taken")
            setNameValidationError(true)
        }
        else {
            setCollectionNameErrorMessage(null)
            setNameValidationError(false)
        }
        return is_unique
    }

    const checkCountValidation = (e) => {
        const pattern = /^([1-9][0-9]*)$/

        if (pattern.test(e.target.value)) {
            return true
        }
        return false
    }

    const descriptionEmptyAfterChange = (plusVisible, value) => {
        console.log("descriptionEmptyAfterChange", value)
        if (!value) {
            setDescriptionValidationError(true)
            return
        }

        if (plusVisible) {
            setDescriptionValidationError(true)
            return
        }

        setDescriptionValidationError(false)
        if (errorsMap.get("description")) {
            removeError("description")
        }
    }

    const descriptionOfYourPostEmptyAfterChange = (plusVisible, value) => {
        console.log("descriptionEmptyAfterChange", value)
        if (!value) {
            setDescriptionOfYourPostValidationError(true)
            return
        }

        if (plusVisible) {
            setDescriptionOfYourPostValidationError(true)
            return
        }

        setDescriptionOfYourPostValidationError(false)
        if (errorsMap.get("description")) {
            removeError("description")
        }
    }

    const handleRandomGen = async () => {
        if (isEventLogoSelected) {
            await handleRandomEventLogo()
        }
        else {
            await handleRandomCollectionLogo()
        }
    }

    const handleRandomEventLogo = async () => {
        const id = "event_image"
        const readOnly = onlyView
        await randomGen(setSelectedEventImageUrl, setEventImageName, id, readOnly)
    }

    const handleRandomCollectionLogo = async () => {
        const id = "collection_image"
        console.log(readOnly, eventId)
        const readonly = onlyView || readOnly || eventId
        await randomGen(setSelectedCollectionImageUrl, setCollectionImageName, id, readonly)
    }

    const randomGen = async (setSelectedFileUrl, setImageName, id, readOnly) => {
        console.log("Random Gen setSelectedFileUrl, setImageName, id, readOnly", setSelectedFileUrl, setImageName, id, readOnly)
        if (readOnly) {
            return
        }
    
        const image = await getRandomImage()
       
    
        if (image)
        {
            const extension = getBase64ImageExtention(image)
            setSelectedFileUrl(image)
            setImageName(`rnd_nft_${getCurrentDatetimeLocal()}.${extension}`)
            document.getElementById(id).src = image;
            
        }
    }
    

    const MinterFirstPage = () => {

        const getRandomButtonReadonly = () => {
            if (onlyView) {
                return true
            }
            if ((readOnly || eventId) && !isEventLogoSelected) {
                return true
            }
            return false
        }




        return (
            <>
                  
                 
                 <div id="first-page-container" style={{marginBottom: `${onlyView ? "0px" : "270px"}`}}>
            {/* <div style={{marginBottom: `${isInputFocused ? "125px" : 0}`, paddingBottom: `${isCountFocused ? "100px" : 0}`}}> */}
               
            <div className="load-images-box">
           <div className="images-container">
                <div  className="images-container-item">
                    <label className="images-container-item-label" >Event logo</label>
                    <NewLoadImage isSelected={isEventLogoSelected} setSelected={() => setIsEventLogoSelected(true)} selectedFileUrl={selectedEventImageUrl} setSelectedFileUrl={setSelectedEventImageUrl} setImageName={setEventImageName} readOnly={onlyView} addError={addError} removeError={removeError} errorsMap={errorsMap} imgWidth={121} imgHeight={121} alt={"Event image"} id={"event_image"}/>
                </div>
                <div  className="images-container-item">
                    <label className="images-container-item-label" >Collection logo</label>
                    <NewLoadImage isSelected={!isEventLogoSelected} setSelected={() => setIsEventLogoSelected(false)} selectedFileUrl={selectedCollectionImageUrl} setSelectedFileUrl={setSelectedCollectionImageUrl} setImageName={setCollectionImageName} readOnly={(onlyView || readOnly || transactionStatus === "success")} addError={addError} removeError={removeError} errorsMap={errorsMap} imgWidth={121} imgHeight={121} alt={"Collection image"} id={"collection_image"} />
                </div>
                
           </div>
           <div className={`btn random-btn no-select ${getRandomButtonReadonly() ? "readonly-btn" : "" }`} onClick={handleRandomGen}>Random gen</div> 
           </div>


               

               

               
              
                <div className="form-box">
                    <form className="minter-form" onSubmit={async (e) => {
                       await handleFormSubmit(e, 1)
                        
                    
                    

                    }
                    
                   
                    
                    }

                    
                        
                    > {/* name, label, value, setValue, checkValidation, readOnly, handleFocus, handleChange, handleBlur */}
                    <div style={{marginBottom: "30px"}}>
                    <InputField label={"Collection name"} name={"collection_name"} value={collectionMetaName ? collectionMetaName : name} setValue={collectionMetaName ? setCollectionMetaName : setName} checkValidation={checkCollectionNameValidation} readOnly={(readOnly || transactionStatus === "success" || onlyView) ? true : false} required={true} maxLength={16} defaultText={collectionNameDefaultText} errorMessage={collectionNameErrorMessage} handleInputEnd={checkIsCollectionNameUnique}/>
                        

                         <InputField label={"Event name"} name={"event_name"} value={eventName} setValue={setEventName} readOnly={transactionStatus === "success" ? true : false} required={true} maxLength={64} defaultText={eventNameDefaultText}/>

                         <TextareaField label={"Description"} name={"description"} value={description} setValue={setDescription} readOnly={onlyView} required={true} maxLength={256} defaultText={descriptionDefaultText} descriptionEmptyAfterChange={descriptionEmptyAfterChange} />

                         <InputField label={"Count"} name={"count"} value={count} setValue={setCount} readOnly={(transactionStatus === "success" || onlyView) ? true : false} required={true}  defaultText={countDefaultText} pattern={'^\\d+$'} />
                        
                                             
                        </div>
                        {checkFirstValidation() ? (
                            <input type="submit" className={`btn form-btn-next no-select`} value="Next" />
                        ) : (
                            <div className={`btn form-btn-next no-select readonly-btn`}>Next</div>
                        )                  

                        }
                     
                        
                        <ShowedWarningModalWindow isOpen={isOpenCollectionNameWarning} setIsOpen={setIsOpenCollectionNameWarning} isConfirm={isConfirmCollectionName} handleSubmit={handleFirstSubmit} />

                    </form>

                    {/* <CheckboxComponent isChecked={isQrChecked} setIsChecked={setIsQrCheched}/> */}

                    

                </div>


            </div>
            </>
        )
    }


        const checkThirdValidation = () => {
            
        }
   

        const handleSendToBot = async (e) => {
            e.preventDefault()
            
            // Добавляем принудительную генерацию QR-кодов перед отправкой
            const generateQRCodes = () => {
                return new Promise((resolve) => {
                    const canvasElements = document.querySelectorAll('.qr-code-canvas');
                    const images = Array.from(canvasElements).map(canvas => {
                        return canvas.toDataURL("image/png");
                    });
                    resolve(images);
                });
            };

            const qrCodes = await generateQRCodes();

            console.log("handleSendToBot Generated QR codes:", qrCodes);

            const sendingData = {
                qrcode: qrCodes,
                description: descriptionOfYourPost ? descriptionOfYourPost : descriptionOfYourPostDefaultText,
                button: button ? button : buttonDefaultText,
                button_url: qrCodeLinks,
                telegram_id: telegramId,
                user_msg: messageForUser,
                event_hashes: hashesArr
            }
            console.log("handleSendToBot Sending data:", sendingData);
            const is_send = await sendDataToBot(sendingData)
            if (is_send) {
                setCloseAppWindowVisible(true)
            } else {
                addError("createPost", "Error creating post. Please try again", "error")
            }
        }


        const QRCodeGenerator = () => {           
            return (
                <>
                    {qrCodeLinks.map((link, index) => (
                        <div key={index} className="qr-container">
                            <QRCode
                                value={link}
                                size={200}
                                id={`qr-code-${index}`}
                                renderAs="canvas"
                                className="qr-code-canvas"
                            />
                        </div>
                    ))}
                </>
            );
        };

          
          const getQRCodeBase64Image = () => {
            const canvas = document.getElementById("qr-code-canvas");
            const dataURL = canvas.toDataURL("image/png");
            return dataURL;
          };
        

        

    const MinterThirdPage = () => {
       
        
        return (
            <>
            <MakePostResultWindow isOpen={closeAppWindowVisible}/>
           
            <QRCodeGenerator />
                
                <div className="form-box" style={{marginBottom: "170px"}}>
                    <form className="minter-form" onSubmit={ async (e) => {
                        await handleSendToBot(e)
                
                }}>

                       
                       <div style={{marginBottom: "42px"}}> 

                        <TextareaField label={"Description"} name={"description_of_you_post"} value={descriptionOfYourPost} setValue={setDescriptionOfYourPost} readOnly={false} required={false} defaultText={descriptionOfYourPostDefaultText}   />

                        <InputField label={"Button"} name={"button"} value={button} setValue={setButton} readOnly={false} required={false} maxLength={256} defaultText={buttonDefaultText} />

                        <TextareaField label={"Message for user"} name={"message_for_user"} value={messageForUser} setValue={setMessageForUser} readOnly={false} required={false} defaultText={messageForUserDefaultText} />
                        </div>
                        <input className="btn form-btn-next no-select" type="submit" value="Send to bot" />
                    </form>
                </div>
            </>
        )
    }
    

    const addError = (errorType, errorMessage, errorStatus) => {
        setErrorsMap((prevErrors) => {
            // Создаем новый Map, копируя предыдущие ошибки
            const newErrorsMap = new Map(prevErrors);
            
            // Добавляем новую ошибку или обновляем существующую
            newErrorsMap.set(errorType, {
                status: errorStatus,
                message: errorMessage,
            });
            
            return newErrorsMap; // Возврат нового Map
        });
    };

    const addOrReplaceChannelAvatar = (subscription, newChannelAvatar) => {
        setChannelAvatars((prev) => {
            const newChannelAvatars = new Map(prev);
            
            // Добавляем новую ошибку или обновляем существующую
            newChannelAvatars.set(subscription, newChannelAvatar);
            
            return newChannelAvatars; // Возврат нового Map
        });
    }   
    
    const removeError = (errorType) => {
        setErrorsMap(prevErrors => {
            const newErrorsMap = new Map(prevErrors);
            newErrorsMap.delete(errorType); // Удаление ошибки по типу
            return newErrorsMap; // Возврат обновленного Map
        });
    };


    const removeChannelAvatar = (subscription) => {
        setChannelAvatars(prev => {
            const newMap = new Map(prev);
            newMap.delete(subscription); 
            return newMap; // Возврат обновленного Map
        });
    };

//     image_name на event_image_name
// image на event_image 

// А также добавить

// collection_image_name
// collection_image

    const sendDataToCreateEvent = async () => {
        const minterData = {
            price: comission,
            collection_name: name,
            event_name: eventName,
            event_description: description,
            nfts_cnt: Number(count),
            event_image_name: eventImageName,
            event_image: selectedEventImageUrl,
            start_date: start,
            end_date: end,
            password: password,
            subscriptions: subscriptions.join(),
            wallet_address: tonConnectUI.account.address,
            event_id: eventId,
            telegram_id: telegramId,
            user_timezone: timeZone,
            collection_image: selectedCollectionImageUrl,
            multiple_qr: false
        };
        // setErrorIndices((prev) => [...prev, idx]);
        //const message = status === "loading" ? "We are creating your event..." : "Error sending data! Please, try again later"
       
        //setErrorsMap((prev) => [...prev, send_status]);
        addError("sendData", "We are creating your event...", "loading")
        


        setSendStatus("loading");
        const event_data = await sendMinterData(minterData);
        
        if (event_data) {
            const event_id = event_data.event_id
            const hashes = event_data.link_hashes
            setHashesArr(hashes)
            console.log("hashes", hashes)
            setSendStatus("success");
            removeError("sendData")
            setEventId(event_id);
            return event_id
           
        } else {
            
            addError("sendData", "Error sending data! Please, try again later", "error")
            setSendStatus("error");
            return null
        }


    }

    const getQRCodeLinks = () => {
        const links_arr = []
        console.log("hashesArr", hashesArr)
        for (let i = 0; i < hashesArr.length; i++) {
            const element = hashesArr[i];
            const link = `https://t.me/${BOT}/${APP_NAME}?startapp=checker-${element}`
            links_arr.push(link)
        }
        console.log("links_arr", links_arr)
        return links_arr
    }

    const sendDataToServer = async () => {
       // setErrorIndices((prev) => [...prev, idx]);
       const event_id = await sendDataToCreateEvent()

      
       if (!event_id) {

        return
       }


       if (transactionStatus === "success" || isTest) {
            setPageNumber(pageNumber + 1);
            window.scrollTo(0, 0);
            //setQRCodeLink(`https://lidum.ru${PORT}/checker/?event_id=${event_id}`)

            

            //console.log(qrCodeLink)
            return
       }

       
        try {
            
       
            const transaction_id = await handleCreateTransaction(event_id)
            
            if (!transaction_id) {     
                return
            }
           
           
            //console.log("sendDataToServer transaction_id", transaction_id)
            if (transaction_id) {
                 
                const status = await waitForTransactionSuccess(transaction_id);
               
                if (status === "success") {
                    setPageNumber(pageNumber + 1);
                    window.scrollTo(0, 0);
                    return
                    
                } else {
                    console.log("Transaction did not complete successfully.");
                }
            } else {
                console.log("Failed to create transaction.");
            }
        } catch (error) {
            setTransactionStatus("error")
            addError("transaction", "Failed to create transaction", "error")
            console.log("handleCreateTransaction error", error)
        }



       

        
    }


    const handlePasswordClick = () => {
        setPassword("")
    }
    
    const MinterSecondPage = () => {

        
       

        return (
            <>
                
            <div className="form-box" style={{marginBottom: `${onlyView ? "0px" : "170px"}`}}>
            {/* <PopupMinter status={sendStatus}/> */}
            {/* <PopupTransactionFailed status={isTransactionError} /> */}
                <form className="minter-form" onSubmit={async (e) => 
                       await handleFormSubmit(e, 2)}>
               
                        
                   {/* <DateTimeBox start={start} end={end} setStart={setStart} setEnd={setEnd} timeZone={timeZone} /> */}

                   <MuiCalendarsBox start={start} end={end} setStart={setStart} setEnd={setEnd} timeZone={timeZone} setTimeZone={setTimeZone} readOnly={onlyView} />
                    
                   {/* <CalendarsBox start={start} end={end} setStart={setStart} setEnd={setEnd} timeZone={timeZone} /> */}

                    {/* <CalendarInput input_id="startDate"
                    error={false}
                    defaultValue={start || ""}
                    setDateTimeInput={setStart} /> */}

                    
                    <div style={{marginBottom: "25px"}}>
                        <InputField label={"Password"} name={"password"} value={password} setValue={setPassword} readOnly={onlyView} required={false} maxLength={32} defaultText={passwordDefaultText} />
                    </div>
                    

                  
                    <div className="form-item margin-bottom-32">
                        <label className="form-item-label">Subscriptions</label>
                        {/* <SubscriptionsInputBox subscriptions={subscriptions} setSubscriptions={setSubscriptions} errorIndices={errorIndices} addSubscription={addSubscription} removeSubscription={removeSubscription} addNotVerifiedSubscription={addNotVerifiedSubscription} removeNotVerifiedSubscription={removeNotVerifiedSubscription}  replaceSubscription={replaceSubscription} setErrorIndices={setErrorIndices} notVerifiedSubscriptions={notVerifiedSubscriptions} channelDublicateIndices={channelDublicateIndices} channelNotExistIndices={channelNotExistIndices} channelInvalidFormatIndices={channelInvalidFormatIndices} onFocus={onSubscriptionFocus} successSubscriptions={successSubscriptions} channelAvatars={channelAvatars}/> */}

                        <NewSubscriptionsInputBox lastFocusIndex={lastFocusIndex} setLastFocusIndex={setLastFocusIndex} subscriptions={subscriptions} errorIndices={errorIndices} addSubscription={addSubscription} removeSubscription={removeSubscription} replaceSubscription={replaceSubscription} channelDublicateIndices={channelDublicateIndices} channelNotExistIndices={channelNotExistIndices} channelInvalidFormatIndices={channelInvalidFormatIndices} onFocus={onSubscriptionFocus} channelAvatars={channelAvatars} addOrReplaceChannelAvatar={addOrReplaceChannelAvatar} removeChannelAvatar={removeChannelAvatar} handleAppearanceKeyboard={handleAppearanceKeyboard} readOnly={onlyView} setIsChannelErrors={setIsChannelErrors}/>
                       
                        {/* <input id="subscriptions" className={subscriptions !== initial_subscriptions ? "form-input with-square" : "form-input with-square subscriptions_initial"} onChange={(e) => setSubscriptions(e.target.value)} type="text" name="subscriptions" onFocus={handleFocusSubscritions} onBlur={handleBlurSubscritions} value={subscriptions} required maxLength={256} /> */}
                    </div>

                  
                    
                    {/* <NumberSelector setInvite={setInvite} defaultValue={invite} /> */}
                    
                    <p className="nft-text">{eventName}</p>
                    {!onlyView && (
                        <div className="comission-box">
                        <div className="comission-box-label">Comission</div>
                        <div>
                            <p className="comission-ton">{comission} TON</p>
                            <p className="comission-usd">&asymp;$ {comissionToUSD}</p>
                        </div>
                    </div>
                    )
                    } 
                  
                    {(checkSecondValidation() && !(transactionStatus === "loading")) ? (
                            <input type="submit" className={`btn form-btn-next no-select`} value="Next" />
                        )                       
                        : 
                        (
                            <div className={`btn form-btn-next no-select readonly-btn`}>Next</div>
                        )                       
                    }

                   
                   
                </form>
            </div>
            </>

        )
    }

    const pagesArr = ["/", MinterFirstPage, MinterSecondPage, MinterThirdPage]

  

    const BackArrowButton = ({ pageNumber }) => {

        
        if (pageNumber === 1) {
            return (
                <div className="arrow arrow-back no-select" onClick={() => {
                    setIsModalOpen(true)
                }
                }>
                </div>          
            )
        }
        return (
            <div className="arrow arrow-back no-select" onClick={() => {
              
                setPageNumber(pageNumber - 1) 
                window.scrollTo(0, 0);               
            }
            }>
            </div>
        )

    }

    const NextArrowButton = ({ pageNumber }) => {

        if (pageNumber >= pagesArr.length - 1) {
            return (
                <div></div>
            )
        }

        return (
            <input className="arrow arrow-next no-select" type="submit" value="" />
            
        )

    }

    useEffect(() => {
        const generateBase64Images = () => {
            const canvasElements = document.querySelectorAll('.qr-code-canvas');
            console.log("generateBase64Images canvasElements", canvasElements)
            const images = Array.from(canvasElements).map(canvas => {
                return canvas.toDataURL("image/png"); // Получаем изображение в формате Base64
            });
            console.log("generateBase64Images images", images)
            setBase64QRCodes(images); // Устанавливаем изображения в state
        };

        // Запускаем генерацию изображений после рендера компонент
        const timeoutId = setTimeout(generateBase64Images, 100); // Немного ждем, чтобы компоненты отрендерились
        return () => clearTimeout(timeoutId); // Очищаем таймер при размонтировке
    }, [qrCodeLinks]);




    useEffect(() => {
        const fetchAuthorInfo = async () => {
            if (wallet) {
                const author_info = await getAuthorInfo(telegramId, username)
               console.log("author_info", author_info)
               console.log("author_info.collection_name", author_info ? author_info.collection_name : "null" )
                if (author_info && author_info.collection_name) {
                    setName(author_info.collection_name)
                    setReadOnly(true)
                    setIsConfirmCollectionName(true)
                    initialValues.name = author_info.collection_name
                    const collection_data = await getCollectionInfo(telegramId, author_info.collection_name)
                    if (collection_data && collection_data.collection_meta_name) {
                        setCollectionMetaName(collection_data.collection_meta_name)
                    }
                }
                if (author_info && author_info.collection_logo_url) {
                    setSelectedCollectionImageUrl(author_info.collection_logo_url)
                    
                }
                
            }
        };

        fetchAuthorInfo(); // Вызов функции для получения информации о пользователе
    }, [wallet, tonConnectUI]);

    if (!wallet) return (
        <>
        
            <IfWalletNotConnected wallet={wallet} message={"Connect your wallet to create a new event"} />
            <WarningModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} navigate={navigate} /> 
            {!telegramId && (
                <div className="arrow arrow-back no-select" onClick={() => {
                    onBackButtonClick(pageNumber)
                }
                }>
                </div>  
            )   

            }
               
        </>
    )

    if (eventStatus) return (
        <>
            
             <NoAccessPage message={eventStatus} />
            
             {!telegramId && (
                <div className="arrow arrow-back no-select" onClick={() => {
                    onBackButtonClick(pageNumber)
                }
                }>
                </div>  
            )   

            }        
            

        </>
    )
    
    return (
        
        <div className="minter-box">
             <WarningModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} navigate={navigate}/> 
            {!telegramId &&
                (
                    <>
                    <BackArrowButton pageNumber={pageNumber}/>
                    </>
                )
            }
            
          
            {pagesArr[pageNumber](pagesArr)}
            <TonConnectButton className='tonConnectButton' style={wallet ? {display: "none"} : {display: "block"}}/>
           
        </div>
    )

}
