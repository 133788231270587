import React, { useState, useRef, useEffect } from 'react';

export const ModalEventElement = ({isOpen, onClose, eventName, collectionName, description, collectionLogoUrl, eventLogoUrl, firstName, username}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const descriptionRef = useRef(null);
    const [showMoreButton, setShowMoreButton] = useState(false);

    useEffect(() => {
        // Сбрасываем состояние развернутости при изменении description
        setIsExpanded(false);
        
        if (descriptionRef.current) {
            const element = descriptionRef.current;
            setShowMoreButton(element.scrollHeight > element.clientHeight);
        }
    }, [description]);

    const handleOverlayClick = (event) => {
        // Проверяем, был ли клик по оверлею
        if (event.target === event.currentTarget) {
            onClose()
        }
      };

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    if (!isOpen) {
        return null
    }


    return (
        <div className="modal-overlay" style={{zIndex: 100, padding: "72px 9px 15px"}} onClick={handleOverlayClick}>
            <div className={`modal-window nft-card-modal ${isExpanded ? 'expanded' : ''}`}>
                <div className="logos-box">
                    <img src={eventLogoUrl} alt="Event Logo In Card" className="event-img-in-card" />
                    <div className="collection-img-in-card-overlay">
                        <img src={collectionLogoUrl} alt="Collection Logo In Card" className="collection-img-in-card" />
                    </div>
                </div>
                <div className="event-info-in-card">
                    <div className="event-name-in-card">
                        {eventName}
                    </div>
                    <div className="event-info-string" style={{marginBottom: "10px"}}>
                        {collectionName}
                    </div>
                    <div 
                        ref={descriptionRef}
                        className={`event-info-string description-string ${showMoreButton ? 'show-more' : ''} ${isExpanded ? 'expanded' : ''}`}
                        style={{whiteSpace: "pre-wrap"}}
                    >
                        {description}
                        {showMoreButton && (
                            <button 
                                className="nfts-card-more-less-button no-select" 
                                onClick={toggleDescription}
                            >
                                {isExpanded ? 'Less' : 'More'}
                            </button>
                        )}
                    </div>
                    <div className="event-info-string" style={{color: "white", fontWeight: "500"}}>
                        About collection
                    </div>
                    <div className="event-info-string">
                        Created by @LidumAppBot
                    </div>
                </div>
                <div className="btn modal-close-button" onClick={onClose}>Back</div>
            </div>
        </div>
    )
}